import Flickity from "flickity";
import "Flickity/dist/flickity.min.css";
import gsap from "gsap";
// Menu Animations
let tl = gsap.timeline({ paused: true });
document.querySelector(".burger-icon").addEventListener("click", () => {
  document.querySelector(".burger-icon").style.display = "none";
  document.querySelector(".close-icon").style.display = "block";
  tl.to(".mob-menu", { duration: 0.5, top: "14%" });
  tl.play();
});

document.querySelector(".close-icon").addEventListener("click", () => {
  document.querySelector(".close-icon").style.display = "none";
  document.querySelector(".burger-icon").style.display = "block";
  tl.reversed(!tl.reversed());
});
var elem = document.querySelector(".main-carousel");
var flkty = new Flickity(elem, {
  // options
  cellAlign: "left",
  contain: true,
});
